import React from 'react';
import './header.css';
import ich from '../../assets/radial_rund.png';
import qrcode from '../../assets/qrcode.svg';



const Header = () => {
  return (
    <div className="nk__header section_padding" id="home">
      <div className="nk__header-content">
        <h1 className="gradient_text"> Personal Training </h1>
        <p>Damit du die beste Version deiner selbst wirst!</p>
      <div className='nk__header-qrcode'>
        <img src={qrcode} alt='ich' />
        <button type="button" onClick={() => window.open("https://api.whatsapp.com/send/?phone=491712991846&text=Herzlichen%20Willkommen%20bei%20Nutrikat.%20Stark%2C%20dass%20du%20die%20beste%20Version%20deiner%20selbst%20werden%20willst%21%20Der%20erste%20Schritt%20ist%20getan.%20Wie%20kann%20ich%20dich%20unterst%C3%BCtzen%3F%20Erz%C3%A4hl%20mir%20gerne%20etwas%20mehr%20von%20dir.%20Du%20wirst%20in%20k%C3%BCrze%20von%20mir%20lesen%C2%A0")}>WhatsApp schicken!</button>
      </div>
      </div>
      <div className='nk__header-image'>
        <img src={ich} alt='ich' />
      </div>
    </div>
  )
}

export default (Header);