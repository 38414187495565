import React from 'react';
import './footer.css';
import nkLogo from '../../assets/logonw.svg';
import { useNavigate } from "react-router-dom";


const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className='nk__footer section__padding'>
      <div className='nk__footer-heading'>
        <h1 className='gradient__Text'>Bist du bereit dein Leben in die Hand zu nehmen?</h1>
      </div>
      <div className='nk__footer-btn'>
        <p onClick={() => window.open("https://api.whatsapp.com/send/?phone=491712991846&text=Herzlichen%20Willkommen%20bei%20Nutrikat%20%F0%9F%A5%B3.%20Stark%2C%20dass%20du%20die%20beste%20Version%20deiner%20selbst%20werden%20willst%20%F0%9F%92%AF%20Der%20erste%20Schritt%20ist%20getan.%20Wie%20kann%20ich%20dich%20unterst%C3%BCtzen%3F%20Erz%C3%A4hl%20mir%20gerne%20etwas%20mehr%20von%20dir.%20Du%20wirst%20in%20k%C3%BCrze%20von%20mir%20lesen%20%F0%9F%92%AC")}>WhatsApp schicken!</p>
      </div>
      <div className='nk__footer-links'>
        <div className='nk__footer-links-logo'>
          <img src={nkLogo} alt='logo'/>
        </div>
        <div className='nk__footer-links_div'>
          <h5 onClick={() => navigate("/Impressum")}>Impressum</h5>
          <h5 onClick={() => navigate("/Datenschutz")}>Datenschutz</h5>
          <h5 onClick={() => navigate("/AGB")}>AGB</h5>
        </div>
        <div className='nk__footer-links_div'>
          <h4>Kontakt aufnehmen</h4>
          <p>WhatsApp: +491712991846</p>
          <p>nutrikat.personaltraining@gmail.com</p>
        </div>
      </div>
      <div className='nk__footer-copyright'>
        <p>© Nutrikat. Alle Rechte vorbehalten.</p>
      </div>
    </div>
  )
}

export default Footer