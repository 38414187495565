import React from 'react';
import './datenschutz.css';


const Datenschutz = () => {
  return (
    <div className='nk__datenschutz'>
      <h1 className='nk__gradient__text'>Datenschutzerklärung: http://www.nutrikat-personaltraining.fit/</h1>
        
        <div className='nk__datenschutz_paragraph'>    
          <h2>1. Begriffsbestimmungen</h2>
          <p>Die Datenschutzerklärung der Larissa Katz beruht auf den Begrifflichkeiten, die durch
              den Europäischen Richtlinien- und Verordnungsgeber beim Erlass der Datenschutz-
              Grundverordnung (DSGVO) verwendet wurden. Unsere Datenschutzerklärung soll
              sowohl für die Öffentlichkeit als auch für unsere Kunden und Geschäftspartner
              einfach lesbar und verständlich sein. Um dies zu gewährleisten, möchten ich vorab
              die verwendeten Begrifflichkeiten erläutern. Ich verwende in dieser Datenschutzerklärung unter anderem die folgenden
              Begriffe:</p>
          <p>(1) Personenbezogene Daten Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder
                  identifizierbare natürliche Person (im Folgenden „betroffene Person") beziehen. Als
                  identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt,
                  insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
                  Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder
                  mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen,
                  genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser
                  natürlichen Person sind, identifiziert werden kann.</p>
          <p>(2) Betroffene Person: Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren
                  personenbezogene Daten von dem für die Verarbeitung Verantwortlichen verarbeitet
                  werden.</p>
          <p>(3) Verarbeitung: Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte
                  Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
                  personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das
                  Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das
                  Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder
                  eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die
                  Einschränkung, das Löschen oder die Vernichtung.</p>
          <p>(4) Einschränkung der Verarbeitung: Einschränkung der Verarbeitung ist die Markierung gespeicherter
                  personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung einzuschränken.</p>
          <p>(5) Profiling: Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die
                  darin besteht, dass diese personenbezogenen Daten verwendet werden, um
                  bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu
                  bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher Lage,
                  Gesundheit, persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten,
                  Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder
                  vorherzusagen.</p>
          <p>(6) Pseudonymisierung: Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise,
                  auf welche die personenbezogenen Daten ohne Hinzuziehung zusätzlicher
                  Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden
                  können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und
                  technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass
                  die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren
                  natürlichen Person zugewiesen werden.</p>
          <p>(7) Verantwortlicher oder für die Verarbeitung Verantwortlicher:
                    Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder
                    juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder
                    gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
                    personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel dieser
                    Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben,
                    so kann der Verantwortliche beziehungsweise können die bestimmten Kriterien
                    seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
                    vorgesehen werden.</p>
          <p>(8)Auftragsverarbeiter:
                  Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung
                  oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen
                  verarbeitet. </p>
          <p>(9) Empfänger:
                  Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder
                  andere Stelle, der personenbezogene Daten offengelegt werden, unabhängig davon,
                  ob es sich bei ihr um einen Dritten handelt oder nicht. Behörden, die im Rahmen
                  eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht
                  der Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten
                  jedoch nicht als Empfänger.</p>
          <p>(10) Dritter:
                  Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere
                  Stelle außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter
                  und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen
                  oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
                  verarbeiten.</p>
          <p>(11) Einwilligung:
                  Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in
                  informierter Weise und unmissverständlich abgegebene Willensbekundung in Form
                  einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der
                  die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie
                  betreffenden personenbezogenen Daten einverstanden ist.</p>
        </div> 

        <div className='nk__datenschutz_paragraph'>    
          <h2>2. Name und Anschrift des für die Verarbeitung Verantwortlichen</h2>
          <p>Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den
              Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze und anderer
              Bestimmungen mit datenschutzrechtlichem Charakter, ist die: </p>
          <p>Herausgeber: Larissa Katz
                Anschrift: Larissa Katz / Nutrikat
                c/o IP-Management #32070
                Ludwig-Erhard-Str. 18
                20459 Hamburg
                Tel.: 01712991846
                E-Mail: nutrikat.personaltraining@gmail.com
                Website: http://www.nutrikat-personaltraining.fit/ </p>
        </div> 

        <div className='nk__datenschutz_paragraph'>    
          <h2>3. Cookies</h2>
          <p> Die Internetseiten der Larissa Katz verwenden Cookies. Cookies sind Textdateien,
                welche über einen Internetbrowser auf einem Computersystem abgelegt und
                gespeichert werden.
                Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten
                eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des
                Cookies. Sie besteht aus einer Zeichenfolge, durch welche Internetseiten und Server
                dem konkreten Internetbrowser zugeordnet werden können, in dem das Cookie
                gespeichert wurde. Dies ermöglicht es den besuchten Internetseiten und Servern,
                den individuellen Browser der betroffenen Person von anderen Internetbrowsern, die
                andere Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann
                über die eindeutige Cookie-ID wiedererkannt und identifiziert werden.
                Durch den Einsatz von Cookies kann die Larissa Katz den Nutzern dieser
                Internetseite nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung
                nicht möglich wären.
                Mittels eines Cookies können die Informationen und Angebote auf unserer
                Internetseite im Sinne des Benutzers optimiert werden. Cookies ermöglichen uns,
                wie bereits erwähnt, die Benutzer unserer Internetseite wiederzuerkennen. Zweck
                dieser Wiedererkennung ist es, den Nutzern die Verwendung unserer Internetseite zu
                erleichtern. Der Benutzer einer Internetseite, die Cookies verwendet, muss
                beispielsweise nicht bei jedem Besuch der Internetseite erneut seine Zugangsdaten
                eingeben, weil dies von der Internetseite und dem auf dem Computersystem des
                Benutzers abgelegten Cookie übernommen wird. Ein weiteres Beispiel ist das Cookie
                eines Warenkorbes im Online-Shop. Der Online-Shop merkt sich die Artikel, die ein
                Kunde in den virtuellen Warenkorb gelegt hat, über ein Cookie.
                Wir verwenden auf unserer Website darüber hinaus Cookies, die eine Analyse des
                Surfverhaltens der Nutzer ermöglichen.
                Auf diese Weise können folgende Daten übermittelt werden:
                Häufigkeit von Seitenaufrufe, 
                Eingegebene Suchbegriffe, 
                Inanspruchnahme von Website-Funktionen.
                Die auf diese Weise erhobenen Daten der Nutzer werden durch technische
                Vorkehrungen pseudonymisiert. Daher ist eine Zuordnung der Daten zum
                aufrufenden Nutzer nicht mehr möglich. Die Daten werden nicht gemeinsam mit
                sonstigen personenbezogenen Daten der Nutzer gespeichert.
                Beim Aufruf unserer Website wird der Nutzer über die Verwendung von Cookies zu
                Analysezwecken informiert und seine Einwilligung zur Verarbeitung der in diesem
                Zusammenhang verwendeten personenbezogenen Daten eingeholt. In diesem
                Zusammenhang erfolgt auch ein Hinweis auf diese Datenschutzerklärung. Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter
                Verwendung von Cookies zu Analysezwecken ist bei Vorliegen einer diesbezüglichen
                Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
                Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite
                jederzeit mittels einer entsprechenden Einstellung des genutzten Internetbrowsers
                verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Ferner
                können bereits gesetzte Cookies jederzeit über einen Internetbrowser oder andere
                Softwareprogramme gelöscht werden. Dies ist in allen gängigen Internetbrowsern
                möglich. Deaktiviert die betroffene Person die Setzung von Cookies in dem
                genutzten Internetbrowser, sind unter Umständen nicht alle Funktionen unserer
                Internetseite vollumfänglich nutzbar.</p>
        </div> 

        <div className='nk__datenschutz_paragraph'>    
          <h2>4. Erfassung von allgemeinen Daten und Informationen </h2>
          <p>Larissa Katz erfasst mit jedem Aufruf der Internetseite durch eine betroffene Person
              oder ein automatisiertes System eine Reihe von allgemeinen Daten und
              Informationen. Diese allgemeinen Daten und Informationen werden in den Logfiles
              des Servers gespeichert. Erfasst werden können die (1) verwendeten Browsertypen
              und Versionen, (2) das vom zugreifenden System verwendete Betriebssystem, (3)
              die Internetseite, von welcher ein zugreifendes System auf unsere Internetseite
              gelangt (sogenannte Referrer), (4) die Unterwebseiten, welche über ein zugreifendes
              System auf unserer Internetseite angesteuert werden, (5) das Datum und die Uhrzeit
              eines Zugriffs auf die Internetseite, (6) eine Internet-Protokoll-Adresse (IP-Adresse),
              (7) der Internet-Service-Provider des zugreifenden Systems und (8) sonstige
              ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle von Angriffen
              auf unsere informationstechnologischen Systeme dienen.
              Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die Larissa Katz
              keine Rückschlüsse auf die betroffene Person. Diese Informationen werden vielmehr
              benötigt, um (1) die Inhalte unserer Internetseite korrekt auszuliefern, (2) die Inhalte
              unserer Internetseite sowie die Werbung für diese zu optimieren, (3) die dauerhafte
              Funktionsfähigkeit unserer informationstechnologischen Systeme und der Technik
              unserer Internetseite zu gewährleisten sowie (4) um Strafverfolgungsbehörden im
              Falle eines Cyberangriffes die zur Strafverfolgung notwendigen Informationen
              bereitzustellen. Diese anonym erhobenen Daten und Informationen werden durch die
              Larissa Katz daher einerseits statistisch und ferner mit dem Ziel ausgewertet, den
              Datenschutz und die Datensicherheit in unserem Unternehmen zu erhöhen, um auch
              ein optimales Schutzniveau für die von uns verarbeiteten personenbezogenen Daten
              sicherzustellen. Die anonymen Daten der Server-Logfiles werden getrennt von allen
              durch eine betroffene Person angegebenen personenbezogenen Daten gespeichert.
              Daneben werden auch, soweit die ausdrückliche Erlaubnis der betreffenden Nutzer
              und unter Einhaltung der geltenden Datenschutzbestimmungen vorliegt, folgende
              persönliche Daten erfasst:Vor- und Nachnamen der Nutzer, IP-Adresse der Nutzer, E-Mail-Adresse der Nutzer, Angaben zum Wohnort (Postleitzahl usw.). Die Verarbeitung der personenbezogenen Daten erfolgt aufgrund unseres
              berechtigten Interesses zur Erfüllung unserer vertraglich vereinbarten Leistungen
              und zur Optimierung unseres Online-Angebotes.
              Diese Website können Sie auch besuchen, ohne Angaben zu Ihrer Person zu
              machen. Zur Verbesserung unseres Online-Angebotes speichern wir jedoch (ohne
              Personenbezug) Ihre Zugriffsdaten auf diese Website. Zu diesen Zugriffsdaten
              gehören z. B. die von Ihnen angeforderte Datei oder der Name Ihres Internet-
              Providers. Durch die Anonymisierung der Daten sind Rückschlüsse auf Ihre Person
              nicht möglich.</p>
        </div> 

        <div className='nk__datenschutz_paragraph'>    
          <h2>5. SSL-Verschlüsselung</h2>
          <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem
              aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL)
              über HTTPS</p>
        </div> 

        <div className='nk__datenschutz_paragraph'>    
          <h2>6. Registrierung auf unserer Internetseite</h2>
          <p>Die betroffene Person hat die Möglichkeit, sich auf der Internetseite des für die
              Verarbeitung Verantwortlichen unter Angabe von personenbezogenen Daten zu
              registrieren. Welche personenbezogenen Daten dabei an den für die Verarbeitung
              Verantwortlichen übermittelt werden, ergibt sich aus der jeweiligen Eingabemaske,
              die für die Registrierung verwendet wird. Die von der betroffenen Person
              eingegebenen personenbezogenen Daten werden ausschließlich für die interne
              Verwendung bei dem für die Verarbeitung Verantwortlichen und für eigene Zwecke
              erhoben und gespeichert. Der für die Verarbeitung Verantwortliche kann die
              Weitergabe an einen oder mehrere Auftragsverarbeiter, beispielsweise einen
              Paketdienstleister, veranlassen, der die personenbezogenen Daten ebenfalls
              ausschließlich für eine interne Verwendung, die dem für die Verarbeitung
              Verantwortlichen zuzurechnen ist, nutzt.
              Durch eine Registrierung auf der Internetseite des für die Verarbeitung
              Verantwortlichen wird ferner die vom Internet-Service-Provider (ISP) der betroffenen
              Person vergebene IP-Adresse, das Datum sowie die Uhrzeit der Registrierung
              gespeichert. Die Speicherung dieser Daten erfolgt vor dem Hintergrund, dass nur so
              der Missbrauch unserer Dienste verhindert werden kann, und diese Daten im
              Bedarfsfall ermöglichen, begangene Straftaten aufzuklären. Insofern ist die
              Speicherung dieser Daten zur Absicherung des für die Verarbeitung Verantwortlichen
              erforderlich. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht,
              sofern keine gesetzliche Pflicht zur Weitergabe besteht oder die Weitergabe der
              Strafverfolgung dient.
              Die Registrierung der betroffenen Person unter freiwilliger Angabe
              personenbezogener Daten dient dem für die Verarbeitung Verantwortlichen dazu, der
              betroffenen Person Inhalte oder Leistungen anzubieten, die aufgrund der Natur der
              Sache nur registrierten Benutzern angeboten werden können. Registrierten
              Personen steht die Möglichkeit frei, die bei der Registrierung angegebenen personenbezogenen Daten jederzeit abzuändern oder vollständig aus dem
              Datenbestand des für die Verarbeitung Verantwortlichen löschen zu lassen.
              Der für die Verarbeitung Verantwortliche erteilt jeder betroffenen Person jederzeit auf
              Anfrage Auskunft darüber, welche personenbezogenen Daten über die betroffene
              Person gespeichert sind. Ferner berichtigt oder löscht der für die Verarbeitung
              Verantwortliche personenbezogene Daten auf Wunsch oder Hinweis der betroffenen
              Person, soweit dem keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Die
              Gesamtheit der Mitarbeiter des für die Verarbeitung Verantwortlichen stehen der
              betroffenen Person in diesem Zusammenhang als Ansprechpartner zur Verfügung.</p>
        </div> 

        <div className='nk__datenschutz_paragraph'>    
          <h2>7. Kontaktmöglichkeit über die Internetseite</h2>
          <p>Die Internetseite der Larissa Katz enthält aufgrund von gesetzlichen Vorschriften
              Angaben, die eine schnelle elektronische Kontaktaufnahme zu unserem
              Unternehmen sowie eine unmittelbare Kommunikation mit uns ermöglichen, was
              ebenfalls eine allgemeine Adresse der sogenannten elektronischen Post (E-Mail-
              Adresse) umfasst. Sofern eine betroffene Person per E-Mail oder über ein
              Kontaktformular den Kontakt mit dem für die Verarbeitung Verantwortlichen
              aufnimmt, werden die von der betroffenen Person übermittelten personenbezogenen
              Daten automatisch gespeichert. Solche auf freiwilliger Basis von einer betroffenen
              Person an den für die Verarbeitung Verantwortlichen übermittelten
              personenbezogenen Daten werden für Zwecke der Bearbeitung oder der
              Kontaktaufnahme zur betroffenen Person gespeichert. Es erfolgt keine Weitergabe
              dieser personenbezogenen Daten an Dritte.</p>
        </div> 


        <div className='nk__datenschutz_paragraph'>    
          <h2>8. Routinemäßige Löschung und Sperrung von personenbezogenen Daten</h2>
          <p>Der für die Verarbeitung Verantwortliche verarbeitet und speichert
            personenbezogene Daten der betroffenen Person nur für den Zeitraum, der zur
            Erreichung des Speicherungszwecks erforderlich ist oder sofern dies durch den
            europäischen Richtlinien- und Verordnungsgeber oder einen anderen Gesetzgeber in
            Gesetzen oder Vorschriften, welchen der für die Verarbeitung Verantwortliche
            unterliegt, vorgesehen wurde.
            Entfällt der Speicherungszweck oder läuft eine vom europäischen Richtlinien- und
            Verordnungsgeber oder einem anderen zuständigen Gesetzgeber vorgeschriebene
            Speicherfrist ab, werden die personenbezogenen Daten routinemäßig und
            entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.</p>
        </div> 

        <div className='nk__datenschutz_paragraph'>    
          <h2>9. Rechte der betroffenen Person</h2>
          <p>(1) Recht auf Bestätigung: Jede betroffene Person hat das vom europäischen Richtlinien- und
                  Verordnungsgeber eingeräumte Recht, von dem für die Verarbeitung
                  Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie betreffende
                  personenbezogene Daten verarbeitet werden. Möchte eine betroffene Person dieses
                  Bestätigungsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an einen
                  Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.</p>
          <p>(2) Recht auf Auskunft: Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
                    vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht, jederzeit von
                    dem für die Verarbeitung Verantwortlichen unentgeltliche Auskunft über die zu seiner
                    Person gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft zu
                    erhalten. Ferner hat der Europäische Richtlinien- und Verordnungsgeber der
                    betroffenen Person Auskunft über folgende Informationen zugestanden:
                    • die Verarbeitungszwecke
                    • die Kategorien personenbezogener Daten, die verarbeitet werden
                    • die Empfänger oder Kategorien von Empfängern, gegenüber denen die
                    personenbezogenen Daten offengelegt worden sind oder noch offengelegt
                    werden, insbesondere bei Empfängern in Drittländern oder bei internationalen
                    Organisationen
                    • Falls möglich die geplante Dauer, für die die personenbezogenen Daten
                    gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die
                    Festlegung dieser Dauer
                    • das Bestehen eines Rechts auf Berichtigung oder Löschung der sie
                    betreffenden personenbezogenen Daten oder auf Einschränkung der
                    Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts
                    gegen diese Verarbeitung
                    • das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde
                    • wenn die personenbezogenen Daten nicht bei der betroffenen Person
                    erhoben werden: Alle verfügbaren Informationen über die Herkunft der Daten
                    • das Bestehen einer automatisierten Entscheidungsfindung einschließlich
                    Profiling gemäß Artikel 22 Abs.1 und 4 DSGVO und — zumindest in diesen
                    Fällen — aussagekräftige Informationen über die involvierte Logik sowie die
                    Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung
                    für die betroffene Person
                    Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob
                    personenbezogene Daten an ein Drittland oder an eine internationale Organisation
                    übermittelt wurden. Sofern dies der Fall ist, so steht der betroffenen Person im
                    Übrigen das Recht zu, Auskunft über die geeigneten Garantien im Zusammenhang
                    mit der Übermittlung zu erhalten. Möchte eine betroffene Person dieses
                    Auskunftsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an einen
                    Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.</p>
          <p>(3) Recht auf Berichtigung: Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
              vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die
              unverzügliche Berichtigung sie betreffender unrichtiger personenbezogener Daten zu
              verlangen. Ferner steht der betroffenen Person das Recht zu, unter Berücksichtigung
              der Zwecke der Verarbeitung, die Vervollständigung unvollständiger
              personenbezogener Daten — auch mittels einer ergänzenden Erklärung — zu
              verlangen. Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch
              nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
              Verantwortlichen wenden.</p>
          <p>(4) Recht auf Löschung (Recht auf Vergessen werden): Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
                vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von dem
                Verantwortlichen zu verlangen, dass die sie betreffenden personenbezogenen Daten
                unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft und soweit
                die Verarbeitung nicht erforderlich ist:
                • Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf
                sonstige Weise verarbeitet, für welche sie nicht mehr notwendig sind.
                • Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung
                gemäß Art. 6 Abs. 1 Buchstabe a DSGVO oder Art. 9 Abs. 2 Buchstabe a
                DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die
                Verarbeitung.
                • Die betroffene Person legt gemäß Art. 21 Abs. 1 DSGVO Widerspruch gegen
                die Verarbeitung ein, und es liegen keine vorrangigen berechtigten Gründe für
                die Verarbeitung vor, oder die betroffene Person legt gemäß Art. 21 Abs. 2
                DSGVO Widerspruch gegen die Verarbeitung ein.
                • Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.
                • Die Löschung der personenbezogenen Daten ist zur Erfüllung einer
                rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der
                Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
                • Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
                Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
                Sofern einer der oben genannten Gründe zutrifft und eine betroffene Person die
                Löschung von personenbezogenen Daten, die bei der Larissa Katz gespeichert sind,
                veranlassen möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die
                Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der Larissa Katz wird
                veranlassen, dass dem Löschverlangen unverzüglich nachgekommen wird.Wurden
                die personenbezogenen Daten von der Larissa Katz öffentlich gemacht und ist unser
                Unternehmen als Verantwortlicher gemäß Art. 17 Abs. 1 DSGVO zur Löschung der
                personenbezogenen Daten verpflichtet, so trifft die Larissa Katz unter
                Berücksichtigung der verfügbaren Technologie und der Implementierungskosten
                angemessene Maßnahmen, auch technischer Art, um andere für die
                Datenverarbeitung Verantwortliche, welche die veröffentlichten personenbezogenen
                Daten verarbeiten, darüber in Kenntnis zu setzen, dass die betroffene Person von
                diesen anderen für die Datenverarbeitung Verantwortlichen die Löschung sämtlicher
                Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen
                dieser personenbezogenen Daten verlangt hat, soweit die Verarbeitung nicht
                erforderlich ist. Der Mitarbeiter der Larissa Katz wird im Einzelfall das Notwendige
                veranlassen.</p>
          <p>(5) Recht auf Einschränkung der Verarbeitung: Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
                  vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von dem
                  Verantwortlichen die Einschränkung der Verarbeitung zu verlangen, wenn eine der
                  folgenden Voraussetzungen gegeben ist:
                  Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person
                  bestritten, und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die
                  Richtigkeit der personenbezogenen Daten zu überprüfen.
                  Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die Löschung der
                  personenbezogenen Daten ab und verlangt stattdessen die Einschränkung der
                  Nutzung der personenbezogenen Daten.
                  Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der
                  Verarbeitung nicht länger, die betroffene Person benötigt sie jedoch zur
                  Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                  Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1
                  DSGVO eingelegt und es steht noch nicht fest, ob die berechtigten Gründe des
                  Verantwortlichen gegenüber denen der betroffenen Person überwiegen.
                  Sofern eine der oben genannten Voraussetzungen gegeben ist und eine betroffene
                  Person die Einschränkung von personenbezogenen Daten, die bei der Larissa Katz
                  gespeichert sind, verlangen möchte, kann sie sich hierzu jederzeit an einen
                  Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der
                  Larissa Katz wird die Einschränkung der Verarbeitung veranlassen.</p>
          <p>(6) Recht auf Datenübertragbarkeit: Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
                vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die sie
                betreffenden personenbezogenen Daten, welche durch die betroffene Person einem
                Verantwortlichen bereitgestellt wurden, in einem strukturierten, gängigen und
                maschinenlesbaren Format zu erhalten. Sie hat außerdem das Recht, diese Daten
                einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem
                die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die
                Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DSGVO oder Art.
                9 Abs. 2 Buchstabe a DSGVO oder auf einem Vertrag gemäß Art. 6 Abs. 1
                Buchstabe b DSGVO beruht und die Verarbeitung mithilfe automatisierter Verfahren
                erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich
                ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt,
                welche dem Verantwortlichen übertragen wurde. Ferner hat die betroffene Person bei
                der Ausübung ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DSGVO
                das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem
                Verantwortlichen an einen anderen Verantwortlichen übermittelt werden, soweit dies
                technisch machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer
                Personen beeinträchtigt werden. Zur Geltendmachung des Rechts auf
                Datenübertragbarkeit kann sich die betroffene Person jederzeit an einen Mitarbeiter
                der Larissa Katz wenden.</p>
          <p>(7) Recht auf Widerspruch: Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
                  vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht, aus Gründen,
                  die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie
                  betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e
                  oder f DSGVO erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese
                  Bestimmungen gestütztes Profiling. Die Larissa Katz verarbeitet die
                  personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei denn, wir
                  können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die den
                  Interessen, Rechten und Freiheiten der betroffenen Person überwiegen, oder die
                  Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
                  Rechtsansprüchen. Verarbeitet die Larissa Katz personenbezogene Daten, um
                  Direktwerbung zu betreiben, so hat die betroffene Person das Recht, jederzeit
                  Widerspruch gegen die Verarbeitung der personenbezogenen Daten zum Zwecke
                  derartiger Werbung einzulegen. Dies gilt auch für das Profiling, soweit es mit solcher
                  Direktwerbung in Verbindung steht. Widerspricht die betroffene Person gegenüber
                  der Larissa Katz der Verarbeitung für Zwecke der Direktwerbung, so wird die Larissa
                  Katz die personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten. Zudem
                  hat die betroffene Person das Recht, aus Gründen, die sich aus ihrer besonderen
                  Situation ergeben, gegen die sie betreffende Verarbeitung personenbezogener
                  Daten, die bei der Larissa Katz zu wissenschaftlichen oder historischen
                  Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1 DSGVO
                  erfolgen, Widerspruch einzulegen, es sei denn, eine solche Verarbeitung ist zur
                  Erfüllung einer im öffentlichen Interesse liegenden Aufgabe erforderlich. Zur
                  Ausübung des Rechts auf Widerspruch kann sich die betroffene Person direkt jeden
                  Mitarbeiter der Larissa Katz oder einen anderen Mitarbeiter wenden. Der betroffenen
                  Person steht es ferner frei, im Zusammenhang mit der Nutzung von Diensten der
                  Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG, ihr
                  Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen
                  technische Spezifikationen verwendet werden.</p>
          <p>(8) Automatisierte Entscheidungen im Einzelfall, einschließlich Profiling: Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
                  vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht, nicht einer
                  ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling —
                  beruhenden Entscheidung unterworfen zu werden, die ihr gegenüber rechtliche
                  Wirkung entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt, sofern die
                  Entscheidung (1) nicht für den Abschluss oder die Erfüllung eines Vertrags zwischen
                  der betroffenen Person und dem Verantwortlichen erforderlich ist, oder (2) aufgrund
                  von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der
                  Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene
                  Maßnahmen zur Wahrung der Rechte und Freiheiten sowie der berechtigten
                  Interessen der betroffenen Person enthalten oder (3) mit ausdrücklicher Einwilligung
                  der betroffenen Person erfolgt. Ist die Entscheidung (1) für den Abschluss oder die
                  Erfüllung eines Vertrags zwischen der betroffenen Person und dem Verantwortlichen
                  erforderlich oder (2) erfolgt sie mit ausdrücklicher Einwilligung der betroffenen
                  Person, trifft die Larissa Katz angemessene Maßnahmen, um die Rechte und
                  Freiheiten sowie die berechtigten Interessen der betroffenen Person zu wahren,
                  wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des
                  Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der
                  Entscheidung gehört. Möchte die betroffene Person Rechte mit Bezug auf
                  automatisierte Entscheidungen geltend machen, kann sie sich hierzu jederzeit an
                  einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.</p>
          <p>(9) Recht auf Widerruf einer datenschutzrechtlichen Einwilligung: Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das
                  vom europäischen Richtlinien- und Verordnungsgeber gewährte Recht, eine
                  Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen.
                  Möchte die betroffene Person ihr Recht auf Widerruf einer Einwilligung geltend
                  machen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
                  Verantwortlichen wenden.</p>
          <p>(10) Verwendung von Bibliotheken (Webfonts): Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend
                  darzustellen, verwenden wir auf dieser Websitebibliotheken und Schriftbibliotheken
                  wie z. B. Google Webfonts (https://www.google.com/webfonts/). Google Webfonts
                  werden zur Vermeidung mehrfachen Ladens in den Cache Ihres Browsers
                  übertragen. Falls der Browser die Google Webfonts nicht unterstützt oder den Zugriff
                  unterbindet, werden Inhalte in einer Standardschrift angezeigt.
                  Der Aufruf vonbibliotheken oder Schriftbibliotheken löst automatisch eine Verbindung
                  zum Betreiber der Bibliothek aus. Dabei ist es theoretisch möglich – aktuell allerdings
                  auch unklar ob und ggf. zu welchen Zwecken – dass Betreiber entsprechender
                  Bibliotheken Daten erheben.
                  Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier: https://
                  www.google.com/policies/privacy/</p>
          <p>(11) Social Media Plug-ins: Auf unseren Webseiten werden Social Plugins der unten aufgeführten Anbieter
                  eingesetzt. Die Plugins können Sie daran erkennen, dass sie mit dem
                  entsprechenden Logo gekennzeichnet sind.
                  Über diese Plugins werden unter Umständen Informationen, zu denen auch
                  personenbezogene Daten gehören können, an den Dienstebetreiber gesendet und
                  ggf. von diesem genutzt. Wir verhindern die unbewusste und ungewollte Erfassung
                  und Übertragung von Daten an den Diensteanbieter durch eine 2-Klick-Lösung. Um
                  ein gewünschtes Social Plugin zu aktivieren, muss dieses erst durch Klick auf den
                  entsprechenden Schalter aktiviert werden. Erst durch diese Aktivierung des Plugins
                  wird auch die Erfassung von Informationen und deren Übertragung an den
                  Diensteanbieter ausgelöst. Wir erfassen selbst keine personenbezogenen Daten
                  mittels der Social Plugins oder über deren Nutzung.
                  Wir haben keinen Einfluss darauf, welche Daten ein aktiviertes Plugin erfasst und wie
                  diese durch den Anbieter verwendet werden. Derzeit muss davon ausgegangen
                  werden, dass eine direkte Verbindung zu den Diensten des Anbieters ausgebaut wird sowie mindestens die IP-Adresse und gerätebezogene Informationen erfasst und
                  genutzt werden. Ebenfalls besteht die Möglichkeit, dass die Diensteanbieter
                  versuchen, Cookies auf dem verwendeten Rechner zu speichern. Welche konkreten
                  Daten hierbei erfasst und wie diese genutzt werden, entnehmen Sie bitte den
                  Datenschutzhinweisen des jeweiligen Diensteanbieters. Hinweis: Falls Sie zeitgleich
                  bei Facebook angemeldet sind, kann Facebook Sie als Besucher einer bestimmten
                  Seite identifizieren.
                  Wir haben auf unserer Website die Social-Media-Buttons folgender Unternehmen
                  eingebunden:
                  Instagram https://www.instagram.com/nutrikat_pt?igsh=bmFucng1bnJseGVx&utm_source=qr</p>
        </div> 

        <div className='nk__datenschutz_paragraph'>    
          <h2> 10. Zahlungsart</h2>
          <p>(1) Paypal: Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
                  Komponenten von PayPal integriert. PayPal ist ein Online-Zahlungsdienstleister.
                  Zahlungen werden über sogenannte PayPal-Konten abgewickelt, die virtuelle Privat-
                  oder Geschäftskonten darstellen. Zudem besteht bei PayPal die Möglichkeit, virtuelle
                  Zahlungen über Kreditkarten abzuwickeln, wenn ein Nutzer kein PayPal-Konto
                  unterhält. Ein PayPal-Konto wird über eine E-Mail-Adresse geführt, weshalb es keine
                  klassische Kontonummer gibt. PayPal ermöglicht es, Online-Zahlungen an Dritte
                  auszulösen oder auch Zahlungen zu empfangen. PayPal übernimmt ferner
                  Treuhänderfunktionen und bietet Käuferschutzdienste an.
                  Die Europäische Betreibergesellschaft von PayPal ist die PayPal (Europe) S.à.r.l. &
                  Cie. S.C.A., 22-24 Boulevard Royal, 2449 Luxembourg, Luxemburg.
                  Wählt die betroffene Person während des Bestellvorgangs in unserem Online-Shop
                  als Zahlungsmöglichkeit „PayPal" aus, werden automatisiert Daten der betroffenen
                  Person an PayPal übermittelt. Mit der Auswahl dieser Zahlungsoption willigt die
                  betroffene Person in die zur Zahlungsabwicklung erforderliche Übermittlung
                  personenbezogener Daten ein.
                  Bei den an PayPal übermittelten personenbezogenen Daten handelt es sich in der
                  Regel um Vorname, Nachname, Adresse, Email-Adresse, IP-Adresse,
                  Telefonnummer, Mobiltelefonnummer oder andere Daten, die zur
                  Zahlungsabwicklung notwendig sind. Zur Abwicklung des Kaufvertrages notwendig
                  sind auch solche personenbezogenen Daten, die im Zusammenhang mit der
                  jeweiligen Bestellung stehen.
                  Die Übermittlung der Daten bezweckt die Zahlungsabwicklung und die
                  Betrugsprävention. Der für die Verarbeitung Verantwortliche wird PayPal
                  personenbezogene Daten insbesondere dann übermitteln, wenn ein berechtigtes
                  Interesse für die Übermittlung gegeben ist. Die zwischen PayPal und dem für die
                  Verarbeitung Verantwortlichen ausgetauschten personenbezogenen Daten werden von PayPal unter Umständen an Wirtschaftsauskunfteien übermittelt. Diese
                  Übermittlung bezweckt die Identitäts- und Bonitätsprüfung.
                  PayPal gibt die personenbezogenen Daten gegebenenfalls an verbundene
                  Unternehmen und Leistungserbringer oder Subunternehmer weiter, soweit dies zur
                  Erfüllung der vertraglichen Verpflichtungen erforderlich ist oder die Daten im Auftrag
                  verarbeitet werden sollen.
                  Die betroffene Person hat die Möglichkeit, die Einwilligung zum Umgang mit
                  personenbezogenen Daten jederzeit gegenüber PayPal zu widerrufen. Ein Widerruf
                  wirkt sich nicht auf personenbezogene Daten aus, die zwingend zur
                  (vertragsgemäßen) Zahlungsabwicklung verarbeitet, genutzt oder übermittelt werden
                  müssen.
                  Die geltenden Datenschutzbestimmungen von PayPal können unter https://
                  www.paypal.com/de/webapps/mpp/ua/privacy-full abgerufen werden.</p>
        </div> 

        <div className='nk__datenschutz_paragraph'>    
          <h2>11. Impressum-Privatschutz (IP-Management) </h2>
          <p>(1) Wir verwenden für die Verwaltung der an uns gesendeten Post die Dienstleistungen
                der IMPRESSUM- PRIVATSCHUTZ GmbH, Ludwig-Erhard-Str. 18, 20459 Hamburg.
                Dieser Dienstleister bietet eine sichere und zuverl‰ssige Postanschrift für unser
                Projekt (z.B. Impressum, Datenschutzerkl‰rung, Widerrufsbelehrung und ggf.
                weitere Bereiche). Dies stellt auch unser berechtigtes Interesse im Sinne des Art. 6
                Abs. 1 lit. f DSGVO dar. Wir haben mit Impressum-Privatschutz einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen die strengen Vorgaben der
                deutschen Datenschutzbehörden bei der Nutzung dieser Dienstleistung vollst‰ndig
                um. Weitere Informationen ̧ ber Datenschutz bei Impressum-Privatschutz GmbH
                finden Sie hier: https://impressum- privatschutz.de/datenschutzerklaerung/ “</p>
        </div> 

        <div className='nk__datenschutz_paragraph'>    
          <h2>12. Rechtsgrundlage der Verarbeitung</h2>
          <p>Art. 6 I lit. a DSGVO dient unserem Unternehmen als Rechtsgrundlage für
              Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen bestimmten
              Verarbeitungszweck einholen. Ist die Verarbeitung personenbezogener Daten zur
              Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist,
              erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für
              eine Lieferung von Waren oder die Erbringung einer sonstigen Leistung oder
              Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 I lit. b DSGVO.
              Gleiches gilt für solche Verarbeitungsvorgänge die zur Durchführung vorvertraglicher
              Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zur unseren Produkten
              oder Leistungen. Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch
              welche eine Verarbeitung von personenbezogenen Daten erforderlich wird, wie
              beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert die Verarbeitung auf
              Art. 6 I lit. c DSGVO. In seltenen Fällen könnte die Verarbeitung von
              personenbezogenen Daten erforderlich werden, um lebenswichtige Interessen der
              betroffenen Person oder einer anderen natürlichen Person zu schützen. Dies wäre
              beispielsweise der Fall, wenn ein Besucher in unserem Betrieb verletzt werden
              würde und daraufhin sein Name, sein Alter, seine Krankenkassendaten oder sonstige
              lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte
              weitergegeben werden müssten. Dann würde die Verarbeitung auf Art. 6 I lit. d
              DSGVO beruhen. Letztlich könnten Verarbeitungsvorgänge auf Art. 6 I lit. f DSGVO
              beruhen. Auf dieser Rechtsgrundlage basieren Verarbeitungsvorgänge, die von
              keiner der vorgenannten Rechtsgrundlagen erfasst werden, wenn die Verarbeitung
              zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines
              Dritten erforderlich ist, sofern die Interessen, Grundrechte und Grundfreiheiten des
              Betroffenen nicht überwiegen. Solche Verarbeitungsvorgänge sind uns insbesondere
              deshalb gestattet, weil sie durch den europäischen Gesetzgeber besonders erwähnt
              wurden. Er vertrat insoweit die Auffassung, dass ein berechtigtes Interesse
              anzunehmen sein könnte, wenn die betroffene Person ein Kunde des
              Verantwortlichen ist (Erwägungsgrund 47 Satz 2 DSGVO).</p>
        </div> 

        <div className='nk__datenschutz_paragraph'>    
          <h2>13.  Berechtigte Interessen an der Verarbeitung, die von dem Verantwortlichen
                    oder einem Dritten verfolgt werden</h2>
          <p>Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit. f DSGVO ist
              unser berechtigtes Interesse die Durchführung unserer Geschäftstätigkeit zugunsten
              des Wohlergehens all unserer Mitarbeiter und unserer Anteilseigner.</p>
        </div> 

        <div className='nk__datenschutz_paragraph'>    
          <h2> 14. Dauer, für die die personenbezogenen Daten gespeichert werden</h2>
          <p>Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die
              jeweilige gesetzliche Aufbewahrungsfrist. Nach Ablauf der Frist werden die
              entsprechenden Daten routinemäßig gelöscht, sofern sie nicht mehr zur
              Vertragserfüllung oder Vertragsanbahnung erforderlich sind.</p>
        </div> 

        <div className='nk__datenschutz_paragraph'>    
          <h2>15. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der
              personenbezogenen Daten; Erforderlichkeit für den Vertragsabschluss;
              Verpflichtung der betroffenen Person, die personenbezogenen Daten
              bereitzustellen; mögliche Folgen der Nichtbereitstellung </h2>
          <p>Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener Daten zum
                Teil gesetzlich vorgeschrieben ist (z.B. Steuervorschriften) oder sich auch aus
                vertraglichen Regelungen (z.B. Angaben zum Vertragspartner) ergeben kann.
                Mitunter kann es zu einem Vertragsschluss erforderlich sein, dass eine betroffene
                Person uns personenbezogene Daten zur Verfügung stellt, die in der Folge durch
                uns verarbeitet werden müssen. Die betroffene Person ist beispielsweise verpflichtet
                uns personenbezogene Daten bereitzustellen, wenn unser Unternehmen mit ihr
                einen Vertrag abschließt. Eine Nichtbereitstellung der personenbezogenen Daten
                hätte zur Folge, dass der Vertrag mit dem Betroffenen nicht geschlossen werden
                könnte. Vor einer Bereitstellung personenbezogener Daten durch den Betroffenen
                muss sich der Betroffene an einen unserer Mitarbeiter wenden. Unser Mitarbeiter
                klärt den Betroffenen einzelfallbezogen darüber auf, ob die Bereitstellung der
                personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben oder für den
                Vertragsabschluss erforderlich ist, ob eine Verpflichtung besteht, die
                personenbezogenen Daten bereitzustellen, und welche Folgen die
                Nichtbereitstellung der personenbezogenen Daten hätte.</p>
        </div> 

        <div className='nk__datenschutz_paragraph'>    
          <h2>16.  Änderung der Datenschutzbestimmung</h2>
          <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den
              aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer
              Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer
              Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>
        </div> 

        <div className='nk__datenschutz_paragraph'>    
          <h2>17. Bestehen einer automatisierten Entscheidungsfindung</h2>
          <p>Als verantwortungsbewusstes Unternehmen verzichten wir auf eine automatische
            Entscheidungsfindung oder ein Profiling.</p>
        </div> 

    </div>
  )
}

export default Datenschutz
