import React from 'react';
import logo from '../../assets/logonw.svg';
import './navbar.css';

const Menu = () => (
  <>
    <p> <a href="#home">Startseite</a> </p>
    <p> <a href="#what">Was dich erwartet</a> </p>
    <p> <a href="#features">Mein Versprechen</a> </p>
    <p> <a href="#possibility">Über mich</a> </p>
  </>
)



//BEM -> Block Element Modifier

const Navbar = () => {

  return (
    <div className="nk__navbar">
      <div className="nk__navbar-links">
        <div className="nk__navbar-links_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="nk__navbar-links_container"> 
         < Menu/>
        </div>
      </div>
      <div className="nk__navbar-sign">
        <button type="button" onClick={() => window.open("https://api.whatsapp.com/send/?phone=491712991846&text=Herzlichen%20Willkommen%20bei%20Nutrikat.%20Stark%2C%20dass%20du%20die%20beste%20Version%20deiner%20selbst%20werden%20willst%21%20Der%20erste%20Schritt%20ist%20getan.%20Wie%20kann%20ich%20dich%20unterst%C3%BCtzen%3F%20Erz%C3%A4hl%20mir%20gerne%20etwas%20mehr%20von%20dir.%20Du%20wirst%20in%20k%C3%BCrze%20von%20mir%20lesen%C2%A0")}>WhatsApp schicken</button>
      </div>
      
    </div>
  );
};

export default Navbar;