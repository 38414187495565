import React from 'react';
import './features.css';
import { Feature } from '../../components';

const featuresData = [
  { 
    title: 'Du stehst im Mittelpunkt',
    text: 'Personal Training bei Nutrikat bedeutet im Mittelpunkt zu stehen.',
  },
  { 
    title: 'Wisenschaftlich fundiertes Training und Ernährung',
    text: ' Du erhältst auf dich angepasste Ernährungs- und Trainingspläne, die nach aktueller wissenschaftlicher Studienlage gestaltet sind.',
  },
  { 
    title: 'Flexibilität, für dich und dein Leben ',
    text: ' Zusätzlich wird dein Training und deine Ernährung auf dich und dein Leben angepasst.',
  },
  { 
    title: 'Jeden Tag einen Schritt  weiterkommen',
    text: 'Durch diese maximale Flexibilität bist du in der Lage jeden Tag einen Schritt weiterzukommen und wirst die beste Version deiner selbst werden!',
  },
];

const Features = () => {
  return (
    <div className='nk__features section__pading' id='features'>
      <div className='nk__features-heading'>
        <h1 className='gradient__text'> Mein Versprechen an dich </h1>
        <p>Damit du weißt worauf du dich einlässt</p>
      </div>
      <div className='nk__features-container'>
        {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  )
}

export default Features