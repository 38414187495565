import React from 'react';
import './possibility.css';
import possibilityImage from '../../assets/gym.png';

const Possibility = () => {
  return (
    <div className='nk__possibility section__padding' id='possibility'>
      <div className='nk__possibility-image'>
        <img src={possibilityImage} alt='possibility'/>
      </div>
      <div className='nk__possibility-content'>
        <h4>Über mich:</h4>
        <h1 className='gradient__text'>Meine Qualifikationen</h1>
        <p>Als erfahrene Personal Trainerin, mit einem Hintergrund in Psychologie und Ernährungsberatung, zeichne ich mich durch eine ganzheitliche Herangehensweise aus. 
          Als Psychologin (M.Sc.), staatlich geprüfte Ernährungsberaterin und Personal Trainerin (B Lizenz) bin ich darauf spezialisiert, nicht nur physisch den Körper zu stärken, 
          sondern auch Hilfestellungen bei mentale Hindernisse zu geben, um gesunde Gewohnheiten langfristig zu etablieren. 
          Als Doktorandin für Ernährung und Motivation bin ich stets bestrebt, meine Expertise zu erweitern und auf dem neuesten Stand zu bleiben, 
          um stets bestmögliche Betreuung bieten zu können. 
          Mein maßgeschneiderter Ansatz basiert auf einer gründlichen Analyse der Bedürfnisse und Ziele, um individuelle Trainings- und Ernährungspläne zu entwickeln, 
          die helfen individuelle Ziele zu erreichen und ein gesundes, ausgewogenes Leben zu führen.</p>
          <h4>Schicke mir eine Nachricht, wenn du bereit bist dein Leben in die Hand zu nehmen!</h4>
      </div>
    </div>
  );
};

export default Possibility;