import React from 'react';
import './impressum.css';


const Impressum = () => {
  return (
    <div className='nk__impressum section__margin' id="impressum">

      <div className='nk__impressum-heading'>
        <h1 className='nk__gradient__text'>Impressum</h1>
        <p>Larissa Katz / Nutrikat <br />
        c/o IP-Management #32070<br />
        Ludwig-Erhard-Str. 18<br />
        20459 Hamburg</p>
      </div>

      <div className='nk__impressum-heading'>
        <h1 className='nk__gradient__text'>Kontakt</h1>
        <p>Telefon: 01712991846<br />
        E-Mail: nutrikat.personaltraining@gmail.com<br />
        Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
      </div>


    </div>
  )
}

export default Impressum

