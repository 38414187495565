import  React from 'react';
import logo from '../../assets/logonw.svg';
import './navbar2.css';
import { useNavigate} from "react-router-dom";





const Navbar2 = () => {
  const navigate = useNavigate();

  return (
    <div className="nk__navbar">
      <div className="nk__navbar-links">
        <div className="nk__navbar-links_logo">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="nk__navbar-sign">
        <button onClick={() => navigate("/")} type="button">Startseite</button>
      </div>
      
    </div>
  );
};

export default Navbar2;