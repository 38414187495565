import React from 'react';
import './what.css';
import { Feature } from '../../components';

const What = () => (
    <div className='nk__what section__margin' id="what">
      <div className='nk__what-feature'>
        <Feature title="Warum NutriKat?" text="Es braucht keine Magie um Gewicht zu verlieren, Muskeln aufzubauen oder fit zu werden. Alles was du brauchst ist ein Plan, der sich in deinen Alltag integrieren lässt,  der ohne Hokuspokus auskommt und klar verständlich ist. Auf Basis der aktuellen Forschungslage erarbeiten wir gemeinsam einen Plan, den du Tag für Tag umsetzen kannst, um endlich deinen Traumkörper im Spiegel zu sehen. Ich stehe dir mit Rat und Tat zur Seite und gemeinsam holen wir das Beste aus dir heraus." />
      </div>
      <div className='nk__what-heading'>
      <h1 className="gradient__text">Was dich erwartet</h1>
      <p>Langfristig ans Ziel</p>
    </div>
    <div className="nk__what-container">
      <Feature title="Ist-Analyse" text="Von Training, über Ernährung bis hin zu deinem Alltag. Wir definieren deine Ziele und besprechen wie diese erreichbar werden." />
      <Feature title="Dein Plan" text="Individuell auf dich angepasst. Trainings- und / oder Ernährungsplan im Einklang mit deinem Alltag." />
      <Feature title="Jeden Tag einen Schritt weiter" text="Gemeinsam verfolgen wir ein langfristiges Konzept." />
    </div>
  </div>
);

export default What;