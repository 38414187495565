import React from 'react';
import {BrowserRouter, Routes,Route} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

import { Navbar, Navbar2} from './components';
import { Features, Footer, Header, Possibility, What} from './containers';
import { Datenschutz, AGB, Impressum} from './additionalsites';

import './App.css';


function App() {


  return (
      <>
          <BrowserRouter>
          
              <Routes>
                  <Route
                      exact
                      path="/"
                      element={
                        <div className='App'>
                          <div className='gradient__bg'>
                            <Navbar />
                            <Header />
                          </div>
                          <What />
                          <Features />
                          <Possibility />
                          
                          
                          <Footer />
                          <ScrollToTop smooth />
                        </div>
                      }
                  />
                  <Route
                      exact
                      path="/Impressum"
                      element={
                        <div>
                          <Navbar2 />
                          <Impressum />
                        </div>}
                  />
                  <Route
                      exact
                      path="/Datenschutz"
                      element={<div>
                        <Navbar2 />
                        <Datenschutz />
                        <ScrollToTop smooth />
                      </div>}
                  />
                  <Route
                      exact
                      path="/AGB"
                      element={<div>
                        <Navbar2 />
                        <AGB />
                        <ScrollToTop smooth />
                      </div>}
                  />
              </Routes>
             
          </BrowserRouter>
      </>
  );
}


export default App
